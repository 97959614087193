import * as React from "react"
import { Layout } from "../components"

const NotFoundPage = () => {
  return (
    <Layout>Not found</Layout>
  )
}

export default NotFoundPage
